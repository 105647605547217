import { cloneDeep, round } from 'lodash-es'
import { checkEmrRules } from './check-emr-rules'
import { PatientInterface } from '../../shared/model/patient'

export function checkVitalToEmrRules(
  value: number,
  type: string,
  patient: PatientInterface
) {
  if (!value) return false
  const rules = cloneDeep(checkEmrRules)
  rules.set(
    !patient.patientAlertRules
      ? patient.defaultAlertRules
      : patient.patientAlertRules
  )
  if (rules) {
    return (
      round(value, 1) < rules.get(type).min ||
      round(value, 1) > rules.get(type).max
    )
  } else {
    return false
  }
}
